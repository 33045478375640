import { Box, SystemProps, Flex, Icon, Spinner } from "@storyofams/react-ui";
import { createClient } from "@supabase/supabase-js";
import Image from "next/image";
import Link from "next/link";
import { useQuery } from "react-query";
import { Co2, Plastic, Trees } from "~components/common/Icons";
import { Text } from "../../Text";

type ImageBannerProps = {
  first?: boolean;
  content: {
    title?: string;
    description: string;
    text_align?: string;
    link_text?: string;
    link?: {
      url: string;
    };
    bg_image?: {
      filename: string;
    };
  };
} & SystemProps;

const values = {
  plastic: {
    icon: Plastic,
    label: "Plastic flessen gered",
    ml: "16px",
  },
  trees: {
    icon: Trees,
    label: "Bomen geplant",
    ml: "27.65px",
  },
  carbon: {
    icon: Co2,
    label: "Kg CO₂ gecompenseerd",
    ml: "29.35px",
  },
};

export const Greenspark = ({ content, first, ...props }: ImageBannerProps) => {
  const supabase = createClient(
    process.env.NEXT_PUBLIC_SUPABASE_URL_SUB,
    process.env.NEXT_PUBLIC_SUPABASE_KEY_SUB
  );
  const getData = async (): Promise<any> => {
    try {
      //@ts-ignore

      let { data, error } = await supabase.from("greenspark_data").select("*");
      if (error) return [];
      return data.reverse();
    } catch (err) {
      console.log(err);
    }
  };
  const { data, isLoading } = useQuery(["getData"], getData);
  return (
    <Box
      height={["740px", "720px"]}
      width={"100%"}
      position={"relative"}
      borderRadius={"8px"}
      overflow={"hidden"}
    >
      {content?.bg_image?.filename && (
        <Box width={"100%"} height={"100%"} css={{ filter: "brightness(74%)" }}>
          <Image
            layout="fill"
            objectFit={"cover"}
            quality={70}
            src={content?.bg_image?.filename}
          />
        </Box>
      )}
      <Box
        top={0}
        right={0}
        left={0}
        mx={"auto"}
        p={["20px", "40px"]}
        position={"absolute"}
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        height={"100%"}
        justifyContent={"space-between"}
      >
        <Box>
          <Box
            mb={[1, 2]}
            mx="auto"
            width={151}
            height={30}
            position={"relative"}
          >
            <Image
              layout="fill"
              src="https://a.storyblok.com/f/125270/454x92/f60881c6b1/greenspark-logo-copy-1.png"
            />
          </Box>
          <Text
            fontWeight={700}
            fontSize={["40px", "48px"]}
            lineHeight={["48px", "57.6px"]}
            fontFamily={"DINPro"}
            textAlign={"center"}
            color={"white"}
            mb={[1, 2]}
          >
            {content?.title}
          </Text>
          <Text
            color={"white"}
            fontSize={["16px", "18px"]}
            textAlign={"center"}
            lineHeight={["22.4px", "25.2px"]}
            maxWidth={"776px"}
            mx="auto"
          >
            {content?.description}
          </Text>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={["end", "space-between"]}
        >
          <Box
            display={"flex"}
            justifySelf={"end"}
            height={"100%"}
            justifyContent={"center"}
            flexDirection={["column", "row"]}
          >
            {isLoading ? (
              <Spinner />
            ) : (
              data &&
              data.map(({ id, value }, i) => (
                <Box
                  ml={[0, i && 2]}
                  mt={[i && 1, 0]}
                  borderRadius={"8px"}
                  minWidth={"265px"}
                  width={["100%", "max-content"]}
                  boxShadow={"4px 4px 40px 0px #00000040"}
                  py={[1, 2]}
                  pr={2}
                  backgroundColor={"white"}
                  key={id}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Icon
                    ml={["31px", values[id].ml]}
                    mr={["29.5px", 2]}
                    fontSize={"45px"}
                    icon={values[id].icon}
                  ></Icon>
                  <Box>
                    <Text
                      fontFamily={"DINPro"}
                      fontWeight={700}
                      lineHeight={["48px", "57.6px"]}
                      color="#1D1D1B"
                      fontSize={["40px", "48px"]}
                    >
                      {value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                    </Text>
                    <Text
                      fontWeight={500}
                      fontSize={"18px"}
                      color="#1D1D1B"
                      lineHeight={"25.2px"}
                      whiteSpace={"nowrap"}
                    >
                      {values[id].label}
                    </Text>
                  </Box>
                </Box>
              ))
            )}
          </Box>
          <Link href={content.link.url}>
            <Text
              mt={[2, "164px"]}
              lineHeight={"25.2px"}
              fontSize={"18px"}
              fontWeight={500}
              cursor={"pointer"}
              textAlign={"center"}
              textDecoration={"underline"}
              color="white"
            >
              {content.link_text}
            </Text>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
